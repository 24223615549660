<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Stock General</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>


                <b-col sm="12" md="7"> </b-col>
                <b-col sm="6" md="4">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListStockGeneral"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button @click="ExportExcel" title="Exportar a Excel" type="button" variant="success" class="form-control"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Codigo</th>
                    <th width="40%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Categoria</th>
                    <th width="10%" class="text-center">U.M.</th>
                    <th width="5%" class="text-center" v-for="(item, it) in mwarehouses" :key="it">{{ item.name }}</th>
                  </tr>
                  <tr>
                    
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-left"> {{ item.category_name }}</td>
                    <td class="text-left"> {{ item.unit_measure }}</td>
                    <td class="text-right" v-for="(stock, it1) in item.stock" :key="it1">
                       {{stock.quantity}}
                    </td>
                
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListStockGeneral" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");

export default {
  name: "RquirementList",
  data() {
    return {
      module: 'StockGeneral',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
      establishments:[],
      mestablishments:[],
      mwarehouses:[],
      id_establishment:'all',
      search: "",
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListStockGeneral();
  },
  methods: {
    ListStockGeneral,
    ExportExcel,
    Permission,
    
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

//listar usuario
function ListStockGeneral() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "kardex/stock-general/"+ search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.table.total;
        me.data_table = response.data.result.stock;
        me.mwarehouses = response.data.result.warehouses;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}


function ExportExcel() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "excel-stock-general/"+ search;

  window.open(url,'_blank');
}


// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
